import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Col, Container, Row } from "reactstrap";
import FormWrapperCard from "../../components/card/FormWrapper";
import { notifyError } from "../../helpers/notifications";
import { useLoader } from "../../providers/loader";
import { recoverPassword } from "../../store/AuthSlice";
import RecoverySuccess from "./RecoverySuccess";
import SendEmailForm from "./SendEmail";

const PasswordRecovery = () => {
  const dispatch = useDispatch();
  const { setLoading } = useLoader();
  const [emailSent, setEmailSent] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      await dispatch(
        recoverPassword({
          email: values.email,
          mobile: "",
          username: "",
        })
      ).unwrap();
      setEmailSent(true);
    } catch (error) {
      notifyError(error, "top-center");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <FormWrapperCard
              title="recovery-page.title"
              description="recovery-page.subtitle"
            >
              <SendEmailForm callback={handleSubmit} />
              {emailSent && <RecoverySuccess />}
            </FormWrapperCard>

            <div className="mt-5 text-center">
              © {new Date().getFullYear()} SAYSOON-CONSOLE
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default PasswordRecovery;