import { isFunction } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import FormikForm from "../../components/form/FormikForm";
import { TextInput } from "../../components/form/FormikInputs";

const validationSchema = Yup.object().shape({
  email: Yup.string().required("validation.required.email"),
});

const initialFormData = {
  email: "",
};

const SendEmailForm = ({ callback }) => {
  const { t } = useTranslation();

  const handleSubmit = async (values) => {
    if(isFunction(callback)){
      callback(values);
    }
  };

  return (
    <FormikForm
      initialValues={initialFormData}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <TextInput
        name="email"
        label="form.email"
        placeholder="form.placeholder.email"
      />

      <div className="mt-3 d-grid">
        <button
          className="btn btn-primary btn-block waves-effect waves-light"
          type="submit">
          {t("reset-page.button")}
        </button>
      </div>
    </FormikForm>
  );
};

export default SendEmailForm;