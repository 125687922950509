import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

const ResetSuccess = () => {
  const history = useHistory();
  const { t } = useTranslation();


  const handleProceed = () => history.push("/login");

  return (
    <div className="my-3 pt-sm-3">
      <div className="d-grid">
        <button onClick={handleProceed} className="btn btn-primary btn-block waves-effect waves-light">{t("reset-page.go-to-login")}</button>
      </div>
    </div>
  );
}

export default ResetSuccess;