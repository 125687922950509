import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router";
import { useHistory } from "react-router-dom";
import { notifyError } from "../helpers/notifications";
import { retrieveAuthUser, LOGOUT_USER } from "../store/AuthSlice";

const AuthWrapper = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { isLoggedIn, userId } = useSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // history.push("/login", { isNotifyPersist: true });

    const fetchAuthUser = async () => {
      try {
        if (!userId) {
          console.log('[Auth Wrapper]', "isnull", userId);
          // throw new Error("No user");
        } else {
          console.log('[Auth Wrapper]', userId);
          await dispatch(retrieveAuthUser({ userId })).unwrap();
        }
      } catch (rejected) {
        const error = rejected?.response?.body?.error || {status: 402};
        console.error('rejected: ', error);

        
        notifyError(error, 'top-center');
        if (error?.status === 401 || error?.status === 402) {
          dispatch(LOGOUT_USER());
          history.push("/login", { isNotifyPersist: true });
        }
      } finally {
        setLoading(false);
      }
    };
    
    if (isLoggedIn) fetchAuthUser();
    else setLoading(false);
  }, [isLoggedIn, userId, history, dispatch]);

  return <div>{!loading && children}</div>;
};

export default AuthWrapper;
