import { isFunction } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import FormikForm from "../../components/form/FormikForm";
import { PasswordInput } from "../../components/form/FormikInputs";
import { notifyWarning } from "../../helpers/notifications";

const validationSchema = Yup.object().shape({
  newPassword: Yup.string().required("validation.required.password"),
  newPasswordConfirmation: Yup.string().required("validation.required.password"),
});

const initialFormData = {
  newPassword: "",
  newPasswordConfirmation: "",
};

const PasswordResetForm = ({ callback }) => {
  const { t } = useTranslation();

  const handleSubmit = (values) => {
    if (values.newPassword !== values.newPasswordConfirmation) {
      notifyWarning("Passwords must match", "top-center");
      return;
    }
    if (isFunction(callback)) {
      callback(values);
    }
  };

  return (
    <FormikForm
      initialValues={initialFormData}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <PasswordInput
        name="newPassword"
        label="reset-page.set-new-password"
        placeholder="form.placeholder.password"
      />

      <PasswordInput
        name="newPasswordConfirmation"
        label="reset-page.verify-new-password"
        placeholder="form.placeholder.password"
      />

      <div className="mt-3 d-grid">
        <button
          className="btn btn-primary btn-block waves-effect waves-light"
          type="submit"
        >
          {t("reset-page.button")}
        </button>
      </div>
    </FormikForm>
  );
};

export default PasswordResetForm;