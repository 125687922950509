import toast from "react-hot-toast";
// import i18n from "../i18n";

export function notifyError(error, position = "top-right") {
  var message = error?.message || "Unknown error";
  if (error?.code === 406) message = "Incorrect username or password";
  if (error?.code === 401) message = "Unauthorized Access";
  if (error?.code === 402) message = "Email doesn't exist";


  toast.error(message,
    {
      duration: 4000,
      position: position,
      style: {
        border: "1px solid #713200",
        padding: "16px",
        color: "#713200",
        background: "#e5e7eb",
      },
    }
  );
}

export function notifySuccess(message, position = "top-right") {
  toast.success(message, {
    duration: 4000,
    position: position,
    style: {
      border: "1px solid #713200",
      padding: "16px",
      color: "#713200",
      background: "#e5e7eb",
    },
  });
}

export function notifyWarning(message, position = "top-right") {
  toast(message, {
    icon: '⚠️',
    duration: 4000,
    position: position,
    style: {
      border: "1px solid #713200",
      padding: "16px",
      color: "#713200",
      background: "#e5e7eb",
    },
  });
}

export function notify(message) {
  toast(message, {
    duration: 4000,
    position: "top-center",
    style: {
      border: "1px solid #713200",
      padding: "16px",
      color: "#713200",
      background: "#e5e7eb",
    },
  });
}

export function notifyPromise(callback, loading, messageSuccess, messageError) {
  toast.promise(callback(), {
    loading,
    success: messageSuccess,
    error: messageError,
  });
}
