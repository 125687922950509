import React from "react";
import { useTranslation } from "react-i18next";

const RecoverySuccess = () => {
  const { t } = useTranslation();

  return (
    <div className="my-3 pt-sm-4">
      <p className="recovery_confirmation_label"> {t("recovery-page.confirmation-label")} </p>
      <p className="recovery_confirmation_text"> {t("recovery-page.confirmation-text")}</p>
    </div>
  );
}

export default RecoverySuccess;