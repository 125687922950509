import React from "react";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import FormikForm from "../../components/form/FormikForm";
import { TextInput, PasswordInput } from "../../components/form/FormikInputs";
import { loginUser } from "../../store/AuthSlice";
import { notifyError } from "../../helpers/notifications";

const validationSchema = Yup.object().shape({
  email: Yup.string().email().required("validation.required.email"),
  password: Yup.string().required("validation.required.password"),
});

const initialFormData = {
  email: "",
  password: "",
};

const LoginForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSubmit = async (values) => {
    try {
      await dispatch(
        loginUser({
          email: values.email,
          password: values.password,
        })
      ).unwrap();
    } catch (error) {
      notifyError(error, "top-center");
      console.error(error);
    }
  };

  return (
    <FormikForm
      initialValues={initialFormData}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      <TextInput
        name="email"
        label="form.email"
        placeholder="form.placeholder.email"
      />

      <PasswordInput
        name="password"
        label="form.password"
        placeholder="form.placeholder.password"
      />

      <div className="mt-3 d-grid">
        <button
          className="btn btn-primary btn-block waves-effect waves-light"
          type="submit"
        >
          {t("login")}
        </button>
      </div>
    </FormikForm>
  );
};

export default LoginForm;
