import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { Col, Container, Row } from "reactstrap";
import FormWrapperCard from "../../components/card/FormWrapper";
import { notifyError } from "../../helpers/notifications";
import { useLoader } from "../../providers/loader";
import { confirmUser } from "../../store/UserSlice";
import PasswordResetForm from "./ResetPasswordForm";
import ResetSuccess from "./ResetSuccess";

const PasswordReset = () => {
  const { confirmationToken } = useParams();
  const dispatch = useDispatch();
  const [resetSuccess, setResetSuccess] = useState(false);
  const { setLoading } = useLoader();

  const handleSubmit = (values) => {

    setLoading(true);
    const data = {
      token: confirmationToken,
      password: values.newPassword,
      passwordConfirmation: values.newPasswordConfirmation,
    };

    dispatch(confirmUser({ confirmationService: "email", data }))
      .unwrap().then(() => {
        setResetSuccess(true);
      }).catch((error) => {
        if(!error?.message){
          error.message = "Invalid or expired token";
        }
        notifyError(error);
      }).finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="account-pages my-5 pt-sm-5">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <FormWrapperCard
              title={!resetSuccess ? "reset-page.title" : "reset-page.reset-success-title"}
              description={!resetSuccess ? "reset-page.subtitle" : "reset-page.reset-success-text"}
            >
              {!resetSuccess && <PasswordResetForm callback={handleSubmit} />}
              {resetSuccess && <ResetSuccess />}
            </FormWrapperCard>

            <div className="mt-5 text-center">
              © {new Date().getFullYear()} SAYSOON-CONSOLE
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PasswordReset;